@charset "utf-8";

@import '../../sass/partials/functions';
@import '../../sass/partials/variables';
@import '../../sass/partials/mixins';

/* ====
   This file handles the override of default elasticsearch stylings 
   ==== */

$component: 'filter';

%#{$component} {
	box-sizing: border-box;
}

.#{$component}-content {
	margin-bottom: 0;
	[role='heading'] {
		width: 100%;
		padding-bottom: $grid-gutter-width-half;
		&::before {
			@include font($ff-icon);
			content: icon(menu);
			display: inline-block;
			margin-right: $grid-gutter-width-half;
			transform: skew(17deg);
		}
	}
}

.filter-options .filter-options-item {
	background-color: color('white');
	transition: all 150ms ease;
	box-shadow: 0px 0px 5px -5px rgba(color('black'), 0.1);
	border-bottom: 1px solid color('grey-light');
	.filter-options-title {
		padding: 1.5rem;
		position: relative;
		transition: all 150ms ease;
		text-decoration: underline transparent;
		font-size: 1rem !important;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 0;

		&::after {
			position: absolute;
			right: 1.5rem;
			font-size: 0.9rem;
			@include font($ff-icon);
			content: icon(arrow-up);
			text-decoration: none !important;
			transition: all 150ms ease;

			will-change: transform;
			transform: rotate(0deg);
		}

		&[aria-expanded='false']::after {
			transform: rotate(0deg);
		}

		&[aria-expanded='true']::after {
			transform: rotate(180deg);
			color: color('ci');
		}

		&:hover {
			text-decoration: underline color('ci');
			&::after {
				color: color('ci');
			}
		}
	}
	&.active {
		border-bottom: 1px solid transparent;
		box-shadow: 0px 5px 10px -4px rgba(color('black'), 0.1);
	}
	&:hover {
		border-bottom: 1px solid transparent;
		box-shadow: 0px 5px 10px -4px rgba(color('black'), 0.1);
	}
	&:nth-child(2n) {
		margin: $grid-gutter-width-half 0;
	}
}

.filter-options .filter-options-content {
	margin: 0;

	.smile-es-range-slider {
		padding: 1rem 1.5rem 1.5rem 1.5rem;
		.ui-slider {
			position: relative;
			background-color: color('grey-medium');
			margin: 3rem 0 1.5rem;
			border-radius: 15px;
			.ui-slider-range {
				margin: 1rem 0;
				height: 7px;
				position: relative;
				background-color: color('success');
			}
			.ui-slider-handle {
				margin: -27px 0 0 -7px;
				position: absolute;
				background-color: color('white');
				border: 1px solid color('grey-medium');
			}
		}

		.actions-toolbar {
			margin: 0;
			[data-role='message-box'] {
				padding: 0;
				margin-top: auto;
				margin-bottom: auto;
				&.empty {
					font-size: 12px;
				}
			}
			[data-role='apply-range'] {
				line-height: 2;
				margin: 0;
			}
		}
	}

	.item {
		margin: 0;
		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 1rem 1.5rem;
			border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
			background-image: none;

			input[type='checkbox'] {
				position: absolute;
				left: -9999px;

				+ label {
					display: flex;
					flex-direction: row;
					align-items: center;
					color: color('grey');
					position: relative;
					cursor: pointer;
					margin: 0;
					width: 100%;

					&:before,
					&:after {
						content: '';
						display: block;
						position: relative;
						@include prefix(transition, all 150ms ease-out);
						@include prefix(box-sizing, border-box);
					}

					&:before {
						width: 1rem;
						height: 1rem;
						flex-basis: 1rem;
						flex-shrink: 0;
						border-radius: 0;
						border: solid 1px color('grey');
						text-align: center;
						margin-right: $grid-gutter-width/2;
					}

					&:after {
						@include font($ff-icon);
						content: icon('checkmark');
						position: absolute;
						background: none;
						transform: scale(0);
					}
					> .count {
						margin-left: auto;
						font-size: 0.9rem;
					}
				}

				&:checked + label {
					color: color('black');
					&:after {
						transform: scale(0.75);
					}
				}
			}

			&:hover {
				background-image: linear-gradient(
					color('grey-lightest'),
					color('white'),
					color('grey-lightest')
				);
				text-decoration: none;
				input {
					+ label {
						color: color('black');
						&:before {
							border-color: color('grey-lighter');
						}
						&:after {
							color: color('success');
							transform: scale(0.66);
						}
					}
					&:checked + label {
						color: color('black');
						&:before {
							border-color: color('grey-lighter');
						}
						&:after {
							content: icon('cross');
							color: color('ci');
							transform: scale(0.66) rotate(90deg);
						}
					}
				}
			}
			@include respondMax(point('max-sm')) {
				padding: 1rem;
				margin: 0.5rem 0;
				input[type='checkbox'] + label {
					color: color('black') !important;
				}
			}
		}
		&:last-of-type a {
			margin: 0;
			border-bottom: none;
		}
	}
	input[type='text'] {
		display: block;
		margin: auto;
		width: calc(100% - 3rem);
		margin-bottom: 0.5rem;
		&:focus {
			box-shadow: 0 0 3px 1px color('category-healthy');
		}
	}

	.actions {
		margin: 0;
		padding: 1rem;
		border-top: 1px dashed rgba(0, 0, 0, 0.1);
		.secondary a {
			color: color('black');
			text-decoration: none;
		}
	}
}

.filter-current .filter-current-subtitle {
	margin-bottom: 0;

	ol li {
		margin-left: 0;
	}
}

.filter-actions {
	margin-top: $grid-gutter-width;
}

// component class selector
.#{$component} {
	@extend %#{$component};
}
