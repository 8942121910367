@charset "utf-8";

/* ==========================================================================
   Core functions, variables and mixins
   ========================================================================== */
@import 'partials/functions';
@import 'partials/variables';
@import 'partials/mixins';

/* ==========================================================================
   Theme files
   ========================================================================== */
/**
 	the includePath node sass option looks for these files inside the theme folder
 	@see capitan/gulp/tasks/styles.js
*/
@import 'theme';

/* ==========================================================================
   Vendor bootstrap
   ========================================================================== */
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../node_modules/bootstrap/scss/mixins/grid";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities/flex";

/* ==========================================================================
   Default layout partials
   ========================================================================== */
@import 'partials/layout';
@import 'partials/typo';
@import 'partials/icon-font';
@import 'partials/utils';
@import 'partials/print';

/* ==========================================================================
   Components
   ========================================================================== */
@import '../components/bundle/bundle';
@import '../components/pill/pill';
@import '../components/header/header';
@import '../components/nav-meta/nav-meta';
@import '../components/search-main/search-main';
@import '../components/search-result/search-result';
@import '../components/nav-main/nav-main';
@import '../components/nav-mobile/nav-mobile';
@import '../components/section/section';
@import '../components/stage/stage';
@import '../components/breadcrumbs/breadcrumbs';
@import '../components/list/list';
@import '../components/list-recipe/list-recipe';
@import '../components/grid/grid';
@import '../components/form/form';
@import '../components/custom-select/custom-select';
@import '../components/button/button';
@import '../components/accordion/accordion';
@import '../components/footer/footer';
@import '../components/scrolltop/scrolltop';
@import '../components/tooltip/tooltip';
@import '../components/slider/slider';
@import '../components/bluefoot-tabs/bluefoot-tabs';
@import '../components/bluefoot/bluefoot';
@import '../components/fancybox/fancybox';
@import '../components/product-item/product-item';
@import '../components/instagram/instagram';
@import '../components/badge/badge';
@import '../components/account-dropdown/account-dropdown';
@import '../components/account/account';
@import '../components/embed-video/embed-video';
@import '../components/bluefoot/bluefoot';
@import '../components/minicart/minicart';
@import '../components/input-counter/input-counter';
@import '../components/teaser/teaser';
@import '../components/widget-contact/widget-contact';
@import '../components/newsletter/newsletter';
@import '../components/product-detail/product-detail';
@import '../components/checkout/checkout';
@import '../components/message/message';
@import '../components/cart/cart';
@import '../components/post-holder/post-holder';
@import '../components/content-image/content-image';
@import '../components/table/table';
@import '../components/tabs/tabs';
@import '../components/countup/countup';
@import '../components/progress-indicator/progress-indicator';
@import '../components/recipe/recipe';
@import '../components/checkboxaccordion/checkboxaccordion';
@import '../components/filter/filter';

/* ==========================================================================
   Theme Component Overrides
   ========================================================================== */

// e.g. @import "components/stage/stage";


/* ==========================================================================
   Expansion files
   ========================================================================== */
/**
 	the includePath node sass option looks for these files inside the theme folder
 	@see capitan/gulp/tasks/styles.js
*/
@import 'expand';