@import url("//hello.myfonts.net/count/3655e8");

@font-face {
	font-family: 'FFDINWebProRegular';
	font-display: swap;
	src: url('#{$folder-path}/fonts/webfonts/3655E8_1_0.eot');
	src: url('#{$folder-path}/fonts/webfonts/3655E8_1_0.eot?#iefix') format('embedded-opentype'), url('#{$folder-path}/fonts/webfonts/3655E8_1_0.woff2') format('woff2'), url('#{$folder-path}/fonts/webfonts/3655E8_1_0.woff') format('woff'), url('#{$folder-path}/fonts/webfonts/3655E8_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'FFDINWebProBold';
	font-display: swap;
	src: url('#{$folder-path}/fonts/webfonts/3655E8_2_0.eot');
	src: url('#{$folder-path}/fonts/webfonts/3655E8_2_0.eot?#iefix') format('embedded-opentype'), url('#{$folder-path}/fonts/webfonts/3655E8_2_0.woff2') format('woff2'), url('#{$folder-path}/fonts/webfonts/3655E8_2_0.woff') format('woff'), url('#{$folder-path}/fonts/webfonts/3655E8_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Prompt';
	font-display: swap;
	src: url('#{$folder-path}/fonts/prompt/prompt.eot');
	src: url('#{$folder-path}/fonts/prompt/prompt.eot?#iefix') format('embedded-opentype'),url('#{$folder-path}/fonts/prompt/prompt.woff2') format('woff2'), url('#{$folder-path}/fonts/prompt/prompt.woff') format('woff'), url('#{$folder-path}/fonts/prompt/prompt.ttf') format('truetype');
}


h1,
.util-h1,
h2,
.util-h2 {
	margin: 0 0 $grid-gutter-width * 1.5;
	display: block;
}

h3,
.util-h3,
h4,
.util-h4,
h5,
.util-h5,
h6,
.util-h6 {
	margin: 0 0 $grid-gutter-width;
	display: block;
}

h1,
.util-h1,
h3,
.util-h3,
h4,
.util-h4,
h5,
.util-h5,
h6,
.util-h6 {
	@include font($ff-bold);
}

h1,
.util-h1,
h2,
.util-h2,
h3,
.util-h3,
h4,
.util-h4,
h5,
.util-h5 {
	text-transform: uppercase;
}

h1,
.util-h1 {
	@include fontSizeREM(28);
	line-height: 1.13;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(34);
	}
}

h2,
.util-h2 {
	@include font();
	@include fontSizeREM(24);
	line-height: 1.34;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(28);
	}
}

h3,
.util-h3 {
	@include fontSizeREM(26);
	line-height: 1.2;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(32);
	}
}

h4,
.util-h4 {
	@include fontSizeREM(22);
	line-height: 1.32;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(24);
	}
}

h5,
.util-h5,
.util-h5.util-dashed-border--bottom-black{
	@include fontSizeREM(18);
	line-height: 1.25;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(20);
	}
}

.util-h5.util-dashed-border--bottom-black{
	display: flex;
	justify-content: center;
	border-bottom:0;
		p{
			border-bottom:1px dashed color('black');
			padding-bottom: 7px;
			margin:0;
		}
}

h6,
.util-h6 {
	@include fontSizeREM(18);
	line-height: 1.25;
}

.util-h6 {
	text-transform: none;
	font-weight: bold !important;
}

p,
.util-paragraph {
	@include font();
	@include fontSizeREM(14);
	line-height: 1.5;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(16);
	}
}

.util-paragraph-big {
	@include fontSizeREM(16);
	line-height: 1.56;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(18);
	}
}
.util-intro-text p{
	@include fontSizeREM(18);
	line-height: fontSizeREM(30);
	@include respondMax(point('max-xs')) {
		@include fontSizeREM(14);
	}
}
.util-intro {
	@include fontSizeREM(18);
	line-height: 1.38;
	max-width: 720px;
	margin: 0 auto;

	&:before,
	&:after {
		content: '';
		display: block;
		max-width: calc(100% - 100px);
		margin: 0 auto;
	}

	&:before {
		border-top: 1px dashed color('black');
		margin-bottom: $grid-gutter-width;
	}

	&:after {
		border-bottom: 1px dashed color('black');
		margin-top: $grid-gutter-width*1.5;
	}

	@include respondMin(point('min-lg')) {
		@include fontSizeREM(24);
	}
}

small {
	@include fontSizeREM(12);
	line-height: 1.4;

	@include respondMin(point('min-xl')) {
		@include fontSizeREM(13);
	}
}

a {
	@include font($ff-regular, 700);
	color: color('ci');
	text-decoration: none;
	transition: all 150ms ease;

	&:focus {
		outline: none;
	}
}

strong,
b,
.util-strong {
	@include font($ff-bold);

	&,
	span {
		font-weight: 600;
	}
}

ol {
	ol {
		margin-top: $grid-gutter-width-half;
	}
}

sup {
	font-size: 0.75em;
	top: -0.3em;
	line-height: 1;
	left: 0;
	position: relative;
}

