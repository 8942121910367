@charset "utf-8";

$component: 'checkboxaccordion';

%#{$component} {
    height: inherit;
}


.#{$component}-container{
    opacity: 0;
    height: 0px;
    overflow: hidden;
    transition: all 125ms ease;

    &__active{
        opacity: 1;
        height: auto;
    }
}

// component class selector
.#{$component} {
	@extend %#{$component};
}